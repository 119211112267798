import { Injectable, Optional, Inject } from '@angular/core';
import { FormattingService, NumberFormatOptions } from '@vwd/ngx-i18n';
import { PriceFormat, PRICE_FORMATTED_FORMAT } from './price-format';
import { PRICE_PIPES_CONFIG, PricePipesConfig } from './price-pipes.config';

interface DecimalsSpecification {
  minimumFractionDigits: number;
  maximumFractionDigits: number;
}

/**
 * Provides formatting information derived from a PriceFormat.
 *
 * You can configure defaults for values not specified via the PriceFormat
 * via `PricePipesModule.forRoot({ ... })`:
 * ```
PricePipesModule.forRoot({
  defaultDecimals: [
    { securityCategoryCode: 'CROSS', minimumFractionDigits: 5 }, // at least 5
    { securityCategoryCode: 'SHARE', minimumFractionDigits: 2, maximumFractionDigits: 3 }, // at least 2, but allow 3
    { minimumFractionDigits: 0, maximumFractionDigits: 2 }, // default
  ]
})
 ```
 */
@Injectable()
export class PriceFormatService {

  constructor(
    private readonly formattingService: FormattingService,
    @Optional() @Inject(PRICE_PIPES_CONFIG) private readonly config?: PricePipesConfig,
  ) {
  }

  /**
   * Returns a NumberFormatOptions for the specified PriceFormat and configured settings.
   * @param priceFormat
   * @param format
   */
  public getAdjustedFormat(
    priceFormat: PriceFormat,
    format: keyof typeof PRICE_FORMATTED_FORMAT
  ): string | NumberFormatOptions {
    // keep compat between netChange & priceChange (they are identical but intention is different)
    if (format === 'priceChange') {
      format = 'netChange';
    }
    // don't do anything if there's no PriceFormat specified
    if (!priceFormat) {
      return format;
    }

    const formats = this.formattingService.numberFormats;
    let adjustedFormat: NumberFormatOptions;
    let currency = '';

    // check whether we should apply currency logic
    if (priceFormat.addCurrency) {
      // pick best format variant, accounting for currency symbols, etc.
      const fmt = priceFormat.isPercentTraded && formats[format + '[percentTraded]']
        || priceFormat.currency && formats[format + '[withCurrency=' + priceFormat.currency + ']'] // handle XXP, XXC, JPY
        || priceFormat.currency && formats[format + '[withCurrency]'] // handle other currencies
        || formats[format]; // default format

      if (!fmt) {
        throw new Error(`Cannot find price format for '${format}'.`);
      }

      currency = priceFormat.currency;

      if (typeof fmt === 'string') {
        adjustedFormat = { pattern: fmt };
      } else {
        adjustedFormat = { ...fmt };
      }

      // no need to clone, we already have a copy here
      adjustedFormat.currency = currency;
      adjustedFormat.currencySymbol = priceFormat.currencySymbol; // let formatting service handle currency symbol lookup

    } else {

      // pick best format variant without accounting for currency symbols
      const fmt = priceFormat.currency && formats[format + '[currency=' + priceFormat.currency + ']']
        || formats[format]; // default format

      if (!fmt) {
        throw new Error(`Cannot find price format for '${format}'.`);
      }

      if (typeof fmt === 'string') {
        adjustedFormat = { pattern: fmt };
      } else {
        adjustedFormat = fmt;
      }

    }

    if (priceFormat.decimals != null) {
      adjustedFormat = { ...adjustedFormat, minimumFractionDigits: priceFormat.decimals, maximumFractionDigits: priceFormat.decimals };
    } else {
      const defaults = this.getDecimals(priceFormat);
      if (defaults) {
        adjustedFormat = {
          ...adjustedFormat,
          minimumFractionDigits: defaults.minimumFractionDigits,
          maximumFractionDigits: defaults.maximumFractionDigits,
        };
      }
    }

    return adjustedFormat;
  }


  /**
   * Returns the best decimals setting based on the configuration.
   *
   * @param priceFormat The PriceFormat to use to determine the best decimals specification.
   * @returns a decimals specification if found, `undefined` if no match was found.
   */
  public getDecimals(priceFormat: PriceFormat): DecimalsSpecification {
    if (this.config && this.config.defaultDecimals) {
      for (const c of this.config.defaultDecimals) {
        if (matches(c.securityCategoryCode, priceFormat.securityCategoryCode)
          && matches(c.currency, priceFormat.currency)
        ) {
          return {
            minimumFractionDigits: c.minimumFractionDigits,
            maximumFractionDigits: c.maximumFractionDigits == null ? c.minimumFractionDigits : c.maximumFractionDigits,
          };
        }
      }
    }
    return undefined;
  }
}

function matches<T>(filter: T, value: T): boolean {
  if (filter === undefined) {
    return true;
  }
  return filter === value;
}
