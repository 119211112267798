import { InjectionToken } from '@angular/core';

export const PRICE_PIPES_CONFIG = new InjectionToken<PricePipesConfig>('Price pipes config');

export interface PricePipesConfig {
  defaultDecimals?: DefaultDecimalsRule[];
}

export interface DefaultDecimalsRule {
  securityCategoryCode?: string;
  currency?: string;
  minimumFractionDigits: number;
  maximumFractionDigits?: number;
}
