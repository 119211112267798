import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { FormattingService } from '@vwd/ngx-i18n';
import { PriceFormatService } from './price-format.service';
import { defaulted } from './utils';

/**
 * This pipe formats the input value in a percentage value with two digits
 * Uses internally the percent pipe from Angular but does not multiply the value by 100
 */
@Pipe({
  name: 'pctChange'
})
export class PctChangePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    private formattingService: FormattingService,
    private priceFormatService: PriceFormatService,
  ) {
  }

  transform(value: number, args?: any,): any {
    const defaultValue = args ? defaulted(args.defaultValue, this.formattingService.nullText) : this.formattingService.nullText;
    let decimals = (args && args.decimals != null && args.decimals >= 0) ? args.decimals :
      (args && args.decimalNum != null && args.decimalNum >= 0) ? args.decimalNum : 2;

    if (isNaN(value) || value === null) {
      return defaultValue;
    }

    if (isNaN(decimals)) {
      decimals = 2;
    } else if (typeof decimals === 'string') { // for case decimals === '0'
      decimals = parseFloat(decimals);
    }

    const format = this.priceFormatService.getAdjustedFormat({ decimals }, 'pctChange');
    return this.formattingService.formatNumber(value, format);
  }

}
