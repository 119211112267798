import { Pipe, PipeTransform } from '@angular/core';
import { FormattingService } from '@vwd/ngx-i18n';
import { PricePipeFormat } from './price-format';
import { defaulted } from './utils';
import { PriceFormatService } from './price-format.service';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  constructor(
    private formattingService: FormattingService,
    private priceFormatService: PriceFormatService,
  ) {
  }

  transform(value: number, args?: PricePipeFormat): string {
    const defaultValue = args ? defaulted(args.defaultValue, this.formattingService.nullText) : this.formattingService.nullText;

    if (isNaN(value) || (value === null)) {
      return defaultValue;
    }

    const format = this.priceFormatService.getAdjustedFormat(args, 'price');

    return this.formattingService.formatNumber(value, format);
  }

}
