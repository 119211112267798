import { NgModule, Inject, Optional, ModuleWithProviders } from '@angular/core';
import { FormattingModule } from '@vwd/ngx-i18n';
import { DelayQualityIconPipe } from './delay-quality-icon.pipe';
import { DelayQualityPipe } from './delay-quality.pipe';
import { DelayClassPipe } from './delay-class.pipe';
import { DelayPipe } from './delay.pipe';
import { PctChangePipe } from './pct-change.pipe';
import { PctPipe } from './pct.pipe';
import { PriceChangePipe } from './price-change.pipe';
import { PriceFormatService } from './price-format.service';
import { PricePipesConfig, PRICE_PIPES_CONFIG } from './price-pipes.config';
import { PricePipe } from './price.pipe';
import { CurrencySymbolPipe } from './currency-symbol.pipe';

@NgModule({
  imports: [
    FormattingModule,
  ],
  declarations: [
    PricePipe,
    PriceChangePipe,
    PctPipe,
    PctChangePipe,
    DelayQualityIconPipe,
    DelayQualityPipe,
    DelayClassPipe,
    DelayPipe,
    CurrencySymbolPipe
  ],
  exports: [
    PricePipe,
    PriceChangePipe,
    PctPipe,
    PctChangePipe,
    DelayQualityIconPipe,
    DelayQualityPipe,
    DelayClassPipe,
    DelayPipe,
    CurrencySymbolPipe
  ],
})
export class PricePipesModule {
  /**
   * Module constructor that makes sure forRoot was called.
   *
   * @param config
   */
  constructor(@Inject(PRICE_PIPES_CONFIG) @Optional() config: PricePipesConfig) {
    // init check
    if (!config) {
      throw new Error('Missing PricePipesModule.forRoot');
    }
  }

  public static forRoot(pricePipeConfig: PricePipesConfig): ModuleWithProviders<PricePipesModule> {
    return {
      ngModule: PricePipesModule,
      providers: [
        { provide: PRICE_PIPES_CONFIG, useValue: pricePipeConfig || {} },
        PriceFormatService,
      ]
    };
  }
}
