import { Pipe, PipeTransform } from '@angular/core';
import { FormattingService } from '@vwd/ngx-i18n';
import { PricePipeFormat } from './price-format';
import { PriceFormatService } from './price-format.service';
import { defaulted } from './utils';

@Pipe({
  name: 'priceChange'
})
export class PriceChangePipe implements PipeTransform {

  constructor(
    private formattingService: FormattingService,
    private priceFormatService: PriceFormatService,
  ) {
  }

  transform(value: number, args?: PricePipeFormat): any {
    const defaultValue = args ? defaulted(args.defaultValue, this.formattingService.nullText) : this.formattingService.nullText;

    if (isNaN(value) || value === null) {
      return defaultValue;
    }

    const format = this.priceFormatService.getAdjustedFormat(args, 'priceChange');

    return this.formattingService.formatNumber(value, format);
  }

}
