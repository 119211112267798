import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { FormattingService } from '@vwd/ngx-i18n';
import { PercentPipe } from '@vwd/ngx-i18n/pipes';
import { defaulted } from './utils';

/**
 * This pipe formats the input value in a percentage value with two digits
 * Uses internally the percent pipe from Angular but does not multiply the value by 100
 */
@Pipe({
  name: 'pct'
})
export class PctPipe extends PercentPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) locale: string,
      formattingService: FormattingService
  ) {
    super(locale, formattingService);
  }

  transform(value: number | string, args?: any): string | null;
  transform(value: null | undefined, args?: any): null;
  transform(value: number | string | null | undefined, args?: any): string | null {
    const defaultValue = args ? defaulted(args.defaultValue, this.formattingService.nullText) : this.formattingService.nullText;
    let minimumFractionDigits = (args && args.minimumFractionDigits) || 2;
    let maximumFractionDigits = (args && args.maximumFractionDigits) || 2;

    if (isNaN(value as number) || value === null) {
      return defaultValue;
    }

    if (isNaN(minimumFractionDigits)) {
      minimumFractionDigits = 2;
    }

    if (isNaN(maximumFractionDigits)) {
      maximumFractionDigits = 2;
    }

    if (maximumFractionDigits < minimumFractionDigits) {
      maximumFractionDigits = minimumFractionDigits;
    }

    return super.transform((value as number) / 100, `1.${minimumFractionDigits}-${maximumFractionDigits}`);
  }

}
